import * as React from "react";
// import { useHistory } from "react-router-dom";
import CardSection from "./CardSection";
import { Button } from "react-bootstrap";
import Modal from "@material-ui/core/Modal";
//import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { Spinner } from "react-bootstrap";
import { Input } from "reactstrap";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import {
  Elements,
  useElements,
  CardElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";

import useStyles from "../styles/styles";
import { API_URL } from "../constants/API_URL";
import Lottie from "lottie-react";
import success from "../constants/AnimationSuccess.json";
export function CustomSuccess() {

  const [animation, setAnimation] = React.useState(true);

  const handleAnimationComplete = () => {
    setAnimation(false);
  };

  return (
    <div className="flex flex-col w-full justify-center items-center">
      {animation && <Lottie
        animationData={success}
        className="flex justify-center items-center"
        loop={true}
        // hide on complete
        onComplete={handleAnimationComplete}
        style={{
          width: 60,
          height: 60,
          marginTop: -16
        }}

      // run on complete
      // onClick={() => setAnimation(success)}
      />}
    </div>
  );
};




export default inject(
  "cartStore",
  "translateStore",
  "productPageStore"
)(
  observer((props) => {


    console.log("cc logs", props)
    // let history = useHistory();
    const [purchasedProducts, setPurchasedProducts] = React.useState([]);
    const [loadingState, setLoadingState] = React.useState(false);
    const [snNumber, setSnNumber] = React.useState("");
    const [contactButtonDisplay, setContactButtonDisplay] =
      React.useState("none");

    const [productName, SetProductName] = React.useState("");
    const [productNameUser, SetProductNameUser] = React.useState("");

    const [totalPrice, setTotalPrice] = React.useState("0");
    const [currency, setCurrency] = React.useState("eur");
    const [checkoutStatus, setCheckoutStatus] = React.useState(
      "Waiting For Payment"
    );
    const [auth, setAuth] = React.useState({
      token: "",
      user: "",
    });

    const [message11, setMessage11] = React.useState("");
    const [message22, setMessage22] = React.useState("");
    const [message33, setMessage33] = React.useState("");

    const [snState11, setSnState11] = React.useState(0);
    const [snState22, setSnState22] = React.useState(0);
    const [snState33, setSnState33] = React.useState(0);


    const [finalResponse, setFinalResponse] = React.useState("");
    const [loadingResponse, setLoadingResponse] = React.useState(true);
    const [autoActivation, setAutoActivation ] = React.useState(false);

    const [snState11Disabled, setSnState11Disabled] = React.useState(false);
    const [snState22Disabled, setSnState22Disabled] = React.useState(false);
    const [snState33Disabled, setSnState33Disabled] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState("");


    React.useEffect(()=>{
      if(props?.cartStore?.products?.length > 0){
        if (props?.cartStore?.products[0]?.productName.includes("VIP-AF") || props?.cartStore?.products[0]?.productName==="VIP 12 MONTHS" || props?.cartStore?.products[0]?.productName==="VIP 1 MONTH") {
          setAutoActivation(false);
        }
        else {
          setAutoActivation(true);
        }
      }
    }, [props?.cartStore?.products])
    
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value?.split(`; ${name}=`);
      if (parts?.length === 2) return parts.pop().split(';').shift();
    };

    const [checkoutDisplayState, setCheckoutDisplayState] =
      React.useState("block");
    const [stripePromise, setStripePromise] = React.useState(
      // () => loadStripe('pk_test_515Xye4DQFwOA8AVN2CkcnUg4sVjQFFyjejKO0g810l4y5uUAA1F0hLafHbHEQxEO03MUUJuNtGKu7Lp0j3zPw9wd007F610M4X')
      () => loadStripe('pk_live_Eip8Fj3Gz9darQZlxmdPI3VU')
    );

    React.useEffect(() => {
      setUserEmail(getCookie('mxAkskd1dmA'));
    }, [])

    // loadStripe("pk_live_Eip8Fj3Gz9darQZlxmdPI3VU")
    const [emailData, setEmailData] = React.useState({
      email: "",
      subject: "New Message From Order",
      sender: "",
    });


    const checkAttempts = () => {
      fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      console.log(data.ip);
      fetch(`${API_URL}/purchase-attempt`, {
        method: "POST",
        body: JSON.stringify({
          ip: data.ip,
          sn : props?.cartStore?.sn1 || props?.cartStore?.sn2 || props?.cartStore?.sn3 || "",
          status:"Purchase Attempt"
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          console.log("check-attempts", res);
          if (res?.isBan) {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
    }

    const handleMessage = (e) => {
      if (
        !e.origin.includes("aboserver")
        // e.origin != "http://localhost:3000"
      )
        return;
      const { cartStore, productPageStore } = e.data;
      setSnNumber(cartStore?.sn1);
      console.log(cartStore, productPageStore);


      props?.cartStore?.setProducts(cartStore?.products);
      props?.cartStore?.setSN1(cartStore?.sn1);
      props?.cartStore?.setSN2(cartStore?.sn2);
      props?.cartStore?.setSN3(cartStore?.sn3);
      props?.productPageStore?.setProduct(productPageStore?.product);
      setAuth((prevAuth) => ({ ...prevAuth, ...e.data.auth }));

      setTotalPrice(
        toJS(cartStore?.products)?.reduce(
          (acc, e) => acc + parseFloat(e.price),
          0
        )
      );
      setCurrency(cartStore?.products[0]?.currency?.toLowerCase());
      setSnState11(cartStore?.sn1);
      setSnState22(cartStore?.sn2);
      setSnState33(cartStore?.sn3);
    };

    React.useEffect(() => {
      window.addEventListener("message", handleMessage);
      return () => window.removeEventListener("message", handleMessage);
    }, [handleMessage]);

    const [displayEmail, setDisplayEmail] = React.useState({
      form: "block",
      success: "none",
    });

    const [orderNumber, setOrderNumber] = React.useState("");


    React.useEffect(() => {
      checkAttempts();
      let date = new Date();

      let components = [
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
      ];
      let order_no = components.join("");
      setOrderNumber(order_no);
    }, []);

    let currentPrice = 0;

    const [open, setOpen] = React.useState(false);
    /*
    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps
    } = usePaymentInputs(); */

    React.useEffect(() => {
      // if (props?.cartStore?.products?.length === 0) {
      // 	history.push('/');
      // }

      toJS(props?.cartStore?.products).forEach((element) => {
        currentPrice = currentPrice + parseFloat(element.price);
      });

      setTotalPrice(currentPrice);
      console.log(props?.cartStore)

      setSnState11(props?.cartStore?.sn1);
      setSnState22(props?.cartStore?.sn2);
      setSnState33(props?.cartStore?.sn3);

      return function cleanup() {
        props?.cartStore?.setProducts([]);
      };
    }, [props]);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const CheckoutForm = () => {
      const stripe = useStripe();
      const elements = useElements();
      const [PaymentloadingState, setPaymentLoadingState] =
        React.useState(false);

      const request = async (paymentData) => {
        const response = await fetch(`${API_URL}/secret`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        });
        const json = await response.json();
        return json.client_secret;
      };
      const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(document.getElementById("email").value)) {
          setCheckoutStatus("Please input a valid email");
          return;
        }
        setPaymentLoadingState(true);
        
        let newRenewbox = [];
        let newTpoentrace = [];
        let newFuncam = [];
        let newPremiumiptv = [];
        let renovi = [];

        toJS(props?.cartStore?.products).map((value, index) => {
          if (value?.productName === "IKS (Gshare)") {
            newRenewbox.push(1);
          } else if (value?.productName === "IKS+IPTV (Gshare)") {
            newRenewbox.push(1);
          } else if (value?.productName === "IKS+IPTV (Super)") {
            newRenewbox.push(1);
          } else if (value?.productName === "IKS (Super)") {
            newRenewbox.push(1);
          } else if (value?.productName === "IKS (Forever)") {
            newTpoentrace.push(1);
          } else if (value?.productName === "IKS (Forever)") {
            newTpoentrace.push(1);
          } else if (value?.productName === "IKS (Forever)") {
            newTpoentrace.push(1);
          } else if (value?.productName === "IKS (Funcam)") {
            newFuncam.push(1);
          } else if (value?.productName === "IKS+IPTV (Funcam)") {
            newFuncam.push(1);
          } else if (value?.productName === "IKS (Funcam)") {
            newPremiumiptv.push(1);
          }
          else if(value?.productName === "VIP 1 MONTH" || value?.productName === "VIP-AF 1 MONTH") {
            newRenewbox.push(1);
          }
          else if(value?.productName === "VIP 3 MONTHS" || value?.productName === "VIP-AF 3 MONTHS") {
            newRenewbox.push(1);
          }
          else if(value?.productName === "VIP 6 MONTHS" || value?.productName === "VIP-AF 6 MONTHS") {
            newRenewbox.push(1);
          }
          else if(value?.productName === "VIP 12 MONTHS" || value?.productName === "VIP-AF 12 MONTHS" || value?.productName === "TV Stick 1 MONTH" || value?.productName === "TV Stick 3 MONTHS" || value?.productName === "TV Stick 12 MONTHS") {
            newRenewbox.push(1);
          }
          // else if(value?.productName === "VIP 12 MONTHS" || value?.productName === "TV Stick 1 MONTH") {
          //   newRenewbox.push(1);
          // }
          // else if(value?.productName === "VIP 12 MONTHS" || value?.productName === "TV Stick 3 MONTHS") {
          //   newRenewbox.push(1);
          // }
          // else if(value?.productName === "VIP 12 MONTHS" || value?.productName === "TV Stick 12 MONTHS") {
          //   newRenewbox.push(1);
          // }
          
          else if(value?.productName === "IKS (Super) Renovi"){
            renovi.push(1);
          } else if(value?.productName === "IKS IPTV (Super) Renovi"){
            renovi.push(1);
          }
        });
        const paymentData = {
          amount: totalPrice,
          currency,
          order_number: orderNumber,
          email: document.getElementById("email").value,
          name: document.getElementById("name").value,
          rtpf: [
            newRenewbox?.length,
            newTpoentrace?.length,
            newPremiumiptv?.length,
            newFuncam?.length,
            renovi?.length
          ],
        };
        const clientSecret = await request(paymentData);
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          setCheckoutStatus(result.error.message);
          setLoadingState(false);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === "succeeded") {

            let userLogsData = {
              sn: snState11 || snState22 || snState33 || "",
              email: document.getElementById("email").value,
              name: document.getElementById("name").value,
              orderNo: orderNumber,
              orderId: result?.paymentIntent?.id,
              amount: totalPrice,
              productName: productNameUser,
            }
            fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      const userIp = data.ip;
      userLogsData = {...userLogsData, userIp}
            fetch(`${API_URL}/admin-add-logs`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(userLogsData),
            }).catch((err) => {
              Sentry.captureException(err);
              Sentry.captureMessage(`Error in admin-add-logs \n${userLogsData}`);
              console.log(err);
            });
          })

            console.log("userLogsData", userLogsData, "\n<<<<<results>>>>>>\n", result);
            if (newPremiumiptv?.length >= 1) {
              let emailData = {
                email: `
                        <h3>New Premium IPTV Purchase</h3>
                        <br />
                        Adult Content: ${toJS(props?.productPageStore.premiumIPTV).adultContent
                  }
                        <br />
                        Plan: ${toJS(props?.productPageStore.premiumIPTV).planState
                  }
                        <br />
                        Country: ${toJS(props?.productPageStore.premiumIPTV).country
                  }
                        <br />
                        Server: ${toJS(props?.productPageStore.premiumIPTV).server
                  }
                        <br />
                        Sponsor: ${toJS(props?.productPageStore.premiumIPTV).sponsor
                  }
                        <br />
                        Email: ${toJS(props?.productPageStore.premiumIPTV).email}
                        <br />
                        Device: ${toJS(props?.productPageStore.premiumIPTV).device
                  }
                        <br />
                        App: ${toJS(props?.productPageStore.premiumIPTV).app}
                        <br />
                        Mac Address: ${toJS(props?.productPageStore.premiumIPTV).macAddress
                  }
                        `,
                subject: "New Premium IPTV Purchase",
                sender: toJS(props?.productPageStore.premiumIPTV).email,
              };

              fetch(`${API_URL}/send-email`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(emailData),
              }).catch((err) => {
                Sentry.captureException(err);
                Sentry.captureMessage(`Error in send-email \n${emailData}`);
                console.log(err);
              });
            }
            console.log("props?.cartStore?.products", props?.cartStore?.products)
            toJS(props?.cartStore?.products).map((value, index) => {

              console.log("value?.productName", value?.productName)

              if (value?.productName === "GSHAREGSHARE") {
                SetProductName("IKSGshare");
                SetProductNameUser("G-Share Server");
              } else if (value?.productName === "IKS+IPTV (Gshare)") {
                SetProductName("IKSIPTVGshare");
                SetProductNameUser("IPTV Server");
              } else if (value?.productName === "IKS+IPTV (Super)") {
                SetProductName("IKSIPTVGshare");
                SetProductNameUser("IPTV Server");
              } else if (value?.productName === "IKS (Forever)") {
                SetProductName("IKSForever");
                SetProductNameUser("Forever Server");
              } else if (value?.productName === "IKS (Funcam)") {
                SetProductName("IKSFuncam");
                SetProductNameUser("Fucanm Server");
              } else if (value?.productName === "IKS (Super)") {
                SetProductName("IKSSuper");
                SetProductNameUser("IKS Super");

              } else if (value?.productName === "IKS+IPTV (Funcam)") {
                SetProductName("IPTVFuncam");
                SetProductNameUser("IPTV Funcam");

              } else if (value?.productName === "VIP 1 MONTH") {
                SetProductName("PACK1VIP1M");
                SetProductNameUser("VIP 1 MONTH");

              } else if (value?.productName === "VIP 3 MONTHS") {
                SetProductName("PACK1VIP3M");
                SetProductNameUser("VIP 3 MONTHS");

              } else if (value?.productName === "VIP 6 MONTHS") {
                SetProductName("PACK1VIP6M");
                SetProductNameUser("VIP 6 MONTHS");

              } else if (value?.productName === "VIP 12 MONTHS") {
                SetProductName("PACK1VIP12M");
                SetProductNameUser("VIP 12 MONTHS");
                
              } else if (value?.productName === "VIP-AF 1 MONTH") {
                SetProductName("PACK1VIP-AF1M");
                SetProductNameUser("VIP-AF 1 MONTH");

              } else if (value?.productName === "VIP-AF 3 MONTHS") {
                SetProductName("PACK1VIP-AF3M");
                SetProductNameUser("VIP-AF 3 MONTHS");

              } else if (value?.productName === "VIP-AF 6 MONTHS") {
                SetProductName("PACK1VIP-AF6M");
                SetProductNameUser("VIP-AF 6 MONTHS");

              } else if (value?.productName === "VIP-AF 12 MONTHS") {
                SetProductName("PACK1VIP-AF12M");
                SetProductNameUser("VIP-AF 12 MONTHS");

              }
              else if (value?.productName === "TV Stick 1 MONTH") {
                SetProductName("TVSTICK1M");
                SetProductNameUser("TV Stick 1 MONTH");
              }
              else if (value?.productName === "TV Stick 3 MONTHS") {
                SetProductName("TVSTICK3M");
                SetProductNameUser("TV Stick 3 MONTHS");
              }
              else if (value?.productName === "TV Stick 12 MONTHS") {
                SetProductName("TVSTICK12M");
                SetProductNameUser("TV Stick 12 MONTHS");
              }
              else if (value?.productName === "IKS (Super) Renovi") {
                SetProductName("IKSSuperRenovi");
                SetProductNameUser("IKS Super Renovi");
              } else if (value?.productName === "IKS IPTV (Super) Renovi") {
                SetProductName("IKSIPTVSuperRenovi");
                SetProductNameUser("IKS IPTV Super Renovi");
              }
              else {
                SetProductName("IKSGshare");
                SetProductNameUser("G-Share Server")
              }
            });
            setCheckoutStatus("Successfully purchased");
            console.log("Successfully purchased", {
              renewbox: newRenewbox?.length,
              tpoentrance: newTpoentrace?.length,
              funcam: newFuncam?.length,
              premiumiptv: newPremiumiptv?.length,
              renovi: renovi?.length
            },)
            Sentry.captureMessage(`Successfully purchased \n${JSON.stringify({
              renewbox: newRenewbox?.length,
              tpoentrance: newTpoentrace?.length,
              funcam: newFuncam?.length,
              premiumiptv: newPremiumiptv?.length,
              renovi: renovi?.length
            })}`);
            console.log("run test")
            if (newFuncam?.length >= 1) {
              UseSN1();
            } else if (newRenewbox?.length >= 1) {
              UseSN1();
            } else if (newTpoentrace?.length >= 1) {
              UseSN2();
            } else if (newPremiumiptv?.length >= 1) {
              UseSN1();
            } else if (renovi?.length >= 1) {
              UseSN4();
            }
            console.log("run test")
            setPurchasedProducts([
              {
                renewbox: newRenewbox?.length,
                tpoentrance: newTpoentrace?.length,
                funcam: newFuncam?.length,
                premiumiptv: newPremiumiptv?.length,
                renovi: renovi?.length
              }
            ]);
            console.log("run test")

            setCheckoutDisplayState("none");
            props?.cartStore?.setProducts([]);
            setTotalPrice("0");
            setLoadingState(false);
          }
        }
      };
      return (
        <form onSubmit={handleSubmit} width="100%">
          {/* <p style={{ paddingTop: "9px", paddingBottom: "9px" }}>Checkout</p> */}
          <div style={{ display: checkoutDisplayState }}>
            <div className="mb-3">
              <label for="name">Name</label>
              <Input id={"name"} label="Name" variant="outlined" />
              <div className="invalid-feedback">
                <span>Please enter a valid name.</span>
              </div>
            </div>
            <div className="mb-3">
              <label for="email">Email</label>
              <Input id={"email"} label="Email" variant="outlined" />
              <div className="invalid-feedback">
                <span>
                  Please enter a valid email address for shipping updates.
                </span>
              </div>
            </div>
            <label for="card">Card details</label>
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <CardSection />
            </div>
            <div
              style={{
                display: "grid",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              <Button
                id="checkout-btn"
                type={"submit"}
                className="w-100 mb-3"
                variant="success"
                disabled={PaymentloadingState}
              >
                {PaymentloadingState && (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                <span>
                  Pay {totalPrice}
                  {currency === "eur" ? "€" : currency === "usd" ? "$" : "£"}
                </span>
              </Button>
              <p>
                {props?.translateStore.translation.paymentStatus}:{" "}
                {checkoutStatus}
              </p>
              <p style={{ color: "#aaa" }}>
                {props?.translateStore.translation.trustText}
              </p>
            </div>
          </div>
        </form>
      );
    };

    const classes = useStyles();

    const alertUseSN1 = () => {
      if (Number.isInteger(parseInt(snState11))) {
        if (snState11 === "") {
          setMessage11("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length < 12) {
          setMessage11("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length > 12) {
          setMessage11("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);
        Sentry.captureMessage(`alertUseSN1 \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`);
        fetch(
          `${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`?.replaceAll(/undefined/g, ''));

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")

              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);
            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }

              if (res.Results === "" || res.Results?.length === 0)
                setMessage11("S/N has been reactivated");
            } else if (snState22 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }

              if (res.Results === "" || res.Results?.length === 0)
                setMessage22("S/N has been reactivated");
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage33(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
              if (res.Results === "" || res.Results?.length === 0)
                setMessage33("S/N has been reactivated");
            } else {
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setMessage11("Please input a number");
        setLoadingState(false);
      }
    };

    const alertUseSN2 = () => {
      if (Number.isInteger(parseInt(snState22))) {
        if (snState22 === "") {
          setMessage22("S/N less than 12 numbers");
          return;
        }

        if (snState22?.length < 12) {
          setMessage22("S/N less than 12 numbers");
          return;
        }

        if (snState22?.length > 12) {
          setMessage22("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);

        fetch(
          `${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
          .then((res) => {

            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`?.replaceAll(/undefined/g, ''));

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);
            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }

              if (res.Results === "" || res.Results?.length === 0)
                setMessage11("S/N has been reactivated");
            } else if (snState22 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage22(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
              if (res.Results === "" || res.Results?.length === 0)
                setMessage22("S/N has been reactivated");
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage33(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
              if (res.Results === "" || res.Results?.length === 0)
                setMessage33("S/N has been reactivated");
            } else {
              return;
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            console.log(err);
          });
      } else {
        setMessage22("Please input a number");
        setLoadingState(false);
      }
    };

    const alertUseSN3 = () => {
      if (Number.isInteger(parseInt(snState33))) {
        if (snState33 === "") {
          setMessage33("S/N less than 12 numbers");
          return;
        }

        if (snState33?.length < 12) {
          setMessage33("S/N less than 12 numbers");
          return;
        }

        if (snState33?.length > 12) {
          setMessage33("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);

        fetch(
          `${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`?.replaceAll(/undefined/g, ''));

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName}&user=${auth.user}&alert=1&order=${orderNumber}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);

            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }

              if (res.Results === "" || res.Results?.length === 0)
                setMessage11("S/N has been reactivated");
            } else if (snState22 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage22(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
              if (res.Results === "" || res.Results?.length === 0)
                setMessage22("S/N has been reactivated");
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage33(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
              if (res.Results === "" || res.Results?.length === 0)
                setMessage33("S/N has been reactivated");
            } else {
              return;
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            console.log(err);
          });
      } else {
        setMessage33("Please input a number");
        setLoadingState(false);
      }
    };

    const UseSN1 = () => {
      if (props?.cartStore?.products[0].productName === "IKS (Gshare)") {
        SetProductName("IKSGshare");
        SetProductNameUser("G-Share Server");
        var newProductName = "IKSGshare";
      } else if (props?.cartStore?.products[0].productName === "IKS+IPTV (Gshare)") {
        SetProductName("IKSIPTVGshare");
        SetProductNameUser("IPTV Server");
        var newProductName = "IKSIPTVGshare";
      } else if (props?.cartStore?.products[0].productName === "IKS+IPTV (Super)") {
        SetProductName("IKSIPTVGshare");
        SetProductNameUser("IPTV Server");
        var newProductName = "IKSIPTVGshare";
      } else if (props?.cartStore?.products[0].productName === "IKS (Forever)") {
        SetProductName("IKSForever");
        SetProductNameUser("Forever Server");
        var newProductName = "IKSForever";
      } else if (props?.cartStore?.products[0].productName === "IKS (Funcam)") {
        SetProductName("IKSFuncam");
        SetProductNameUser("Fucanm Server");
        var newProductName = "IKSFuncam";
      } else if (props?.cartStore?.products[0].productName === "IKS (Super)") {
        SetProductName("IKSSuper");
        SetProductNameUser("IKS Super");
        var newProductName = "IKSSuper";
      } else if (props?.cartStore?.products[0].productName === "IKS+IPTV (Funcam)") {
        SetProductName("IPTVFuncam");
        SetProductNameUser("IPTV Funcam");
        var newProductName = "IPTVFuncam";
      } else if (props?.cartStore?.products[0].productName === "VIP 1 MONTH") {
        SetProductName("PACK1VIP1M");
        var newProductName = "PACK1VIP1M";
      } else if (props?.cartStore?.products[0].productName === "VIP 3 MONTHS") {
        SetProductName("PACK1VIP3M");
        var newProductName = "PACK1VIP3M";
      } else if (props?.cartStore?.products[0].productName === "VIP 6 MONTHS") {
        SetProductName("PACK1VIP6M");
        var newProductName = "PACK1VIP6M";
      } else if (props?.cartStore?.products[0].productName === "VIP 12 MONTHS") {
        SetProductName("PACK1VIP12M");
        var newProductName = "PACK1VIP12M";
      } else if (props?.cartStore?.products[0].productName === "VIP-AF 1 MONTH") {
        SetProductName("PACK1VIP-AF1M");
        var newProductName = "PACK1VIP-AF1M";
      } else if (props?.cartStore?.products[0].productName === "VIP-AF 3 MONTHS") {
        SetProductName("PACK1VIP-AF3M");
        var newProductName = "PACK1VIP-AF3M";
      } else if (props?.cartStore?.products[0].productName === "VIP-AF 6 MONTHS") {
        SetProductName("PACK1VIP-AF6M");
        var newProductName = "PACK1VIP-AF6M";
      } else if (props?.cartStore?.products[0].productName === "VIP-AF 12 MONTHS") {
        SetProductName("PACK1VIP-AF12M");
        var newProductName = "PACK1VIP-AF12M";
      } else if (props?.cartStore?.products[0].productName === "TV Stick 1 MONTH") {
        SetProductName("TVSTICK1M");
        var newProductName = "TVSTICK1M";
      } else if (props?.cartStore?.products[0].productName === "TV Stick 3 MONTHS") {
        SetProductName("TVSTICK3M");
        var newProductName = "TVSTICK3M";
      } else if (props?.cartStore?.products[0].productName === "TV Stick 12 MONTHS") {
        SetProductName("TVSTICK12M");
        var newProductName = "TVSTICK12M";
      }  
      else if (props?.cartStore?.products[0].productName === "IKS (Super) Renovi") {
        SetProductName("IKSSuperRenovi");
        var newProductName = "IKSSuperRenovi";
      } else if (props?.cartStore?.products[0].productName === "IKS IPTV (Super) Renovi") {
        SetProductName("IKSIPTVSuperRenovi");
        var newProductName = "IKSIPTVSuperRenovi";
      }



      console.log("UseSN1", snState11, "productName", newProductName, props?.cartStore?.products[0].productName)

      if (Number.isInteger(parseInt(snState11))) {
        if (snState11 === "") {
          setMessage11("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length < 12) {
          setMessage11("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length > 12) {
          setMessage11("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);
        if (productName) {

        let emailData = {
          email: `
                <h3>New Purchase</h3>
                <br />
                Order No.: ${orderNumber}
                <br />
                S/N: ${snState11}
                <br />
                Product: ${productName?.replaceAll("Renovi", "")}
                `,
          subject: `New Purchase ${productName?.replaceAll("Renovi", "")}`,
          sender: "www.aboserver.co",
        };

        fetch(`${API_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }).catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
      }


        fetch(
          `${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || newProductName}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice}&userEmail=${userEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`);

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || newProductName}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || newProductName}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);

            if (res.Results === "alert") {
              let r = window.confirm(
                "S/N is still active do you want to continue?"
              );

              if (r === true) {
                alertUseSN1();

                return;
              } else {
                setLoadingState(false);
                setSnState11Disabled(true);
                setSnState22Disabled(false);
                setSnState33Disabled(false);

                return;
              }
            }

            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }
            } else if (snState22 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage22(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage33(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
            } else {
              return;
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            console.log(err);
          });
      } else {
        setMessage11("Please input a number");
        setLoadingState(false);
      }
    };
    const UseSN4 = () => {
      if (props?.cartStore?.products[0].productName === "VIP 1 MONTH") {
        SetProductName("PACK1VIP1M");
        var newProductName = "PACK1VIP1M";
      } else if (props?.cartStore?.products[0].productName === "VIP 3 MONTHS") {
        SetProductName("PACK1VIP3M");
        var newProductName = "PACK1VIP3M";
      } else if (props?.cartStore?.products[0].productName === "VIP 6 MONTHS") {
        SetProductName("PACK1VIP6M");
        var newProductName = "PACK1VIP6M";
      } else if (props?.cartStore?.products[0].productName === "VIP 12 MONTHS") {
        SetProductName("PACK1VIP12M");
        var newProductName = "PACK1VIP12M";
      } else if (props?.cartStore?.products[0].productName === "IKS (Super) Renovi") {
        SetProductName("IKSSuperRenovi");
        var newProductName = "IKSSuperRenovi";
      } else if (props?.cartStore?.products[0].productName === "IKS IPTV (Super) Renovi") {
        SetProductName("IKSIPTVSuperRenovi");
        var newProductName = "IKSIPTVSuperRenovi";
      } else {
        SetProductName("IKSGshare");
        var newProductName = "IKSGshare";
      }
      console.log("UseSN4", snState11, newProductName)

      if (Number.isInteger(parseInt(snState11))) {
        if (snState11 === "") {
          setMessage11("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length < 12) {
          setMessage11("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length > 12) {
          setMessage11("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);
        if (productName) {

        let emailData = {
          email: `
                <h3>New Purchase</h3>
                <br />
                Order No.: ${orderNumber}
                <br />
                S/N: ${snState11}
                <br />
                Product: ${productName?.replaceAll("Renovi", "")}
                `,
          subject: `New Purchase ${productName?.replaceAll("Renovi", "")}`,
          sender: "www.aboserver.co",
        };
      
        fetch(`${API_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }).catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
      }
        fetch(
          `${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || newProductName}&user=${auth.user}&alert=0&order=${orderNumber}&userEmail=${userEmail}&price=${totalPrice}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`?.replaceAll(/undefined/g, ''));

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || newProductName}&user=${auth.user}&alert=0&order=${orderNumber}&userEmail=${userEmail}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || newProductName}&user=${auth.user}&alert=0&order=${orderNumber}&userEmail=${userEmail}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);

            if (res.Results === "alert") {
              let r = window.confirm(
                "S/N is still active do you want to continue?"
              );

              if (r === true) {
                alertUseSN1();

                return;
              } else {
                setLoadingState(false);
                setSnState11Disabled(true);
                setSnState22Disabled(false);
                setSnState33Disabled(false);

                return;
              }
            }

            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  console.log(err);
                });
              }
            } else if (snState22 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage22(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage33(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
            } else {
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setMessage11("Please input a number");
        setLoadingState(false);
      }
    };

    const UseSN2 = () => {
      console.log("UseSN2", snState11)
      if (Number.isInteger(parseInt(snState11))) {
        if (snState11 === "") {
          setMessage22("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length < 12) {
          setMessage22("S/N less than 12 numbers");
          return;
        }

        if (snState11?.length > 12) {
          setMessage22("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);
        if (productName) {
        
        let emailData = {
          email: `
                <h3>New Purchase</h3>
                <br />
                Order No.: ${orderNumber}
                <br />
                S/N: ${snState11}
                <br />
                Product: ${productName?.replaceAll("Renovi", "")}
                `,
          subject: `New Purchase ${productName?.replaceAll("Renovi", "")}`,
          sender: "www.aboserver.co",
        };
        console.log("emailData", emailData)
        fetch(`${API_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }).catch((err) => {
          console.log(err);
        });
      }
        fetch(
          `${API_URL}/use-product-no-user?first=${""}&second=${snState11}&third=${snState33}&product=${productName || "IKSForever"}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice}&userEmail=${userEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`?.replaceAll(/undefined/g, ''));

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${""}&second=${snState11}&third=${snState33}&product=${productName || "IKSForever"}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${""}&second=${snState11}&third=${snState33}&product=${productName || "IKSForever"}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);

            if (res.Results === "alert") {
              let r = window.confirm(
                "S/N is still active do you want to continue?"
              );

              if (r === true) {
                alertUseSN2();

                return;
              } else {
                setLoadingState(false);
                setSnState11Disabled(true);
                setSnState22Disabled(false);
                setSnState33Disabled(false);

                return;
              }
            }

            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }
            } else if (snState11 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage22(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage33(
                  "An error has occurred, please contact us with your order number and S/N"
                );
              }
            } else {
              return;
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
            console.log(err);
          });
      } else {
        setMessage22("Please input a number");
        setLoadingState(false);
      }
    };

    const UseSN3 = () => {
      if (Number.isInteger(parseInt(snState33)) || Number.isInteger(parseInt(snState11))) {
        if (snState33 === "" && snState11 === "") {
          setMessage33("S/N less than 12 numbers");
          return;
        }

        if (snState33?.length < 12 && snState11?.length < 12) {
          setMessage33("S/N less than 12 numbers");
          return;
        }

        if (snState33?.length > 12 && snState11?.length > 12) {
          setMessage33("S/N more than 12 numbers");
          return;
        }

        setLoadingState(true);
        setSnState11Disabled(true);
        setSnState22Disabled(true);
        setSnState33Disabled(true);
        if (productName) {

        let emailData = {
          email: `
                <h3>New Purchase</h3>
                <br />
                Order No.: ${orderNumber}
                <br />
                S/N: ${snState33 || snState11}
                <br />
                Product: ${productName?.replaceAll("Renovi", "")}
                `,
          subject: `New Purchase ${productName?.replaceAll("Renovi", "")}`,
          sender: "www.aboserver.co",
        };
        console.log(emailData)

        fetch(`${API_URL}/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        }).catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
      }
        fetch(
          `${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || "IKSForever"}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice}&userEmail=${userEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.token}`,
            },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            console.log("new response", res);
            if (`${res.message}`.includes("Successfully")){
              setFinalResponse(`${res.message}`?.replaceAll(/undefined/g, ''));

            }
            else if(`${res.message}` == "Unauthorized request, Some thing wrong"){
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Unauthorized request, Some thing wrong \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || "IKSForever"}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice}`);
            }
            else {
              setFinalResponse("Something went wrong. Our team will review your order.")
              Sentry.captureMessage(`Something went wrong. Our team will review your order. \n${API_URL}/use-product-no-user?first=${snState11}&second=${snState22}&third=${snState33}&product=${productName || "IKSForever"}&user=${auth.user}&alert=0&order=${orderNumber}&price=${totalPrice} \n ${res}`);
            }
            setLoadingResponse(false);

            if (res.Results === "alert") {
              let r = window.confirm(
                "S/N is still active do you want to continue?"
              );

              if (r === true) {
                alertUseSN3();

                return;
              } else {
                setLoadingState(false);
                setSnState11Disabled(true);
                setSnState22Disabled(false);
                setSnState33Disabled(false);

                return;
              }
            }

            if (snState11 !== 0) {
              setMessage11(res.Results);
              setLoadingState(false);
              setSnState11Disabled(true);
              setSnState22Disabled(false);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }
            } else if (snState22 !== 0) {
              setMessage22(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(true);
              setSnState33Disabled(false);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }
            } else if (snState33 !== 0) {
              setMessage33(res.Results);
              setLoadingState(false);
              setSnState11Disabled(false);
              setSnState22Disabled(false);
              setSnState33Disabled(true);

              if (
                res.Results === "Please contact us for activation." ||
                res.Results === "Please input sn and card no."
              ) {
                setContactButtonDisplay("block");
                setMessage11(
                  "An error has occurred, please contact us with your order number and S/N"
                );

                let emailData = {
                  email: `
                            <h3>Error</h3>
                            <br />
                            Order No.: #${orderNumber}
                            <br />
                            S/N: ${snState11}
                            <br />
                            Product: ${productName}
                            `,
                  subject: `Error for #${orderNumber}`,
                  sender: "www.aboserver.co",
                };

                fetch(`${API_URL}/send-email`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(emailData),
                }).catch((err) => {
                  Sentry.captureException(err);
                  console.log(err);
                });
              }
            } else {
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setMessage33("Please input a number");
        setLoadingState(false);
      }
    };

    const sendEmail = () => {
      fetch(`${API_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((res) => {
          if (res.status === 200) {
            setDisplayEmail({
              form: "none",
              success: "block",
            });
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    };

    return (
      //<section
      //	className="section section-shaped section-lg bg-gradient-default"
      //	style={{ minHeight: '100vh' }}>
      <div className="container mb-8 p-3">
        <div
          className="row"
        // style={{
        //   display: toJS(props?.cartStore?.products?.length) === 0 ? "none" : "",
        // }}
        >
          {purchasedProducts?.length <= 0 && <div className="col-md-4 order-md-2 mb-4">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Your cart</span>
              <span className="badge badge-secondary badge-pill">
                {props?.cartStore?.products?.length}
              </span>
            </h4>
            <ul className="list-group mb-3">
              {toJS(props?.cartStore?.products?.length) > 0
                ? toJS(props?.cartStore?.products).map((value, index) => {
                  return (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between lh-condensed"
                    >
                      <div>
                        <h6 className="my-0">{value?.product}</h6>
                        <small className="text-muted">
                          {value?.product.includes("alpha")
                            ? "12 Months"
                            : value?.product.includes("bravo")
                              ? "1 Month"
                              : value?.product.includes("trial")
                                ? "24 Hours"
                                : value?.product}
                        </small>
                      </div>
                      <span className="text-muted">{value?.price}</span>
                    </li>
                  );
                })
                : ""}
              <li className="list-group-item d-flex justify-content-between">
                <span>Total</span>
                <strong>
                  {totalPrice}
                  {/* {currency === "eur" ? "€" : currency === "usd" ? "$" : "£"} */}
                </strong>
              </li>
            </ul>
          </div>}
          <div className="col-md-8 order-md-1">
            {purchasedProducts?.length > 0 ? "" : <h4 className="mb-3">Billing information</h4>}
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
        </div>
        <div className={classes.checkoutDiv}>
          {purchasedProducts?.length > 0 ? (
            purchasedProducts.map((value, index) => {
              console.log("value", value, index)
              if (index === 1) {
              if (parseInt(value?.renewbox) > 0 && !snState11Disabled) {
                UseSN1()
              }
              if (parseInt(value?.renovi) > 0 && !snState11Disabled) {
                UseSN4()
              }
              if (parseInt(value?.tpoentrance) > 0 && !snState22Disabled) {
                UseSN2()
              }
              if (parseInt(value?.funcam) > 0 && !snState33Disabled) {
                UseSN3()
              }
            }
              return (
                <div key={index} style={{ height: "100%", display: "flex", flexDirection: "center", justifyContent: "center" }}>
                  <div
                    style={{
                      marginTop: "15px",
                      borderRadius: "6px",
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: -60
                    }}>
                      <CustomSuccess />

                      <p>
                        <span style={{

                          fontSize: "18px",
                          fontWeight: "500",
                        }}>
                          {props?.translateStore.translation.purchasedProducts}
                        </span>
                      </p>
                    </div>
                    <p style={{

                      fontSize: "18px",
                      fontWeight: "500",
                    }}>Order number <span style={{

                      fontSize: "18px",
                      fontWeight: "bold",
                    }}>
                        #{orderNumber}
                      </span>
                    </p>

                    
                    {
                      !autoActivation && <div>
                        
                        <p style={{

                          fontSize: "18px",
                          fontWeight: "bold",
                        }}>
                          Order successfully placed! Subscription under activation <span style={{
                            fontWeight: "normal",
                          }}>
                            
                             (it may take up to 6h)
                            </span>

                        </p>
                        </div>
                    }

                    <div>
                      {parseInt(value?.premiumiptv) > 0 ? (
                        <div>
                          <div className="text-center alert alert-success">
                            <span style={{

                              fontSize: "18px",
                              fontWeight: "500",
                            }}>
                              Premium IPTV Purchased. Confirmation sent to email
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>

                    <div className={classes.purchasedProdDiv}>
                      <div
                        onClick={() => {
                          setSnState22(0);
                          setSnState33(0);
                        }}
                      >
                        {parseInt(value?.renewbox) > 0 ? (
                          <div>
                            <p>
                              <span style={{

                                fontSize: "18px",
                                fontWeight: "500",
                              }}>{productNameUser}</span>
                            </p>

                            <p
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                                color: "#268e00",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              {/* <span>{message11}</span> */}
                            </p>

                            {/* <Input
                              type="tel"
                              label="S/N"
                              value={snState11}
                              onChange={(e) => setSnState11(e.target.value)}
                            /> */}
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>


                              <p style={{

                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: 0
                              }}>
                                {snState11}
                              </p>
                            </div>
                            {/* <p 
        style={{
          color: "#34deeb",
        }}
        >
          {finalResponse.replace('"', "").replace("'", "")}
          <br />
          {finalResponse.includes("wrong") && (
            "Our team will review your order."
          )}
        </p> */}

                            <Button
                              style={{
                                display: contactButtonDisplay,
                                margin: "0 auto",
                                marginTop: "12px",
                              }}
                              variant="primary"
                              color="primary"
                              onClick={handleOpen}
                            >
                              <span style={{

                                fontSize: "18px",
                                fontWeight: "bold",
                              }}>Contact Us</span>
                            </Button>


                            {/* <Button
                              style={{ marginTop: "15px" }}
                              variant="primary"
                              color="primary"
                              onClick={UseSN1}
                              disabled={snState11Disabled}
                            >
                              {loadingState && (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              )}
                              <span>Activation is in progress</span>
                            </Button> */}
                          </div>
                        ) : (
                          <div>
                            <div />
                          </div>
                        )}
                      </div>

                      <div
                        onClick={() => {
                          setSnState11(0);
                          setSnState33(0);
                        }}
                      >
                        {parseInt(value?.tpoentrance) > 0 ? (
                          <div>
                            <p>
                              <span>{productNameUser}</span>
                            </p>

                            <p
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                                color: "#268e00",
                              }}
                            >
                              {/* <span>{message22}</span> */}
                            </p>

                            {/* <TextField
                              type="tel"
                              label="S/N"
                              variant="filled"
                              value={snState22}
                              onChange={(e) => setSnState22(e.target.value)}
                            /> */}
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>

                              <p style={{

                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: 0
                              }}>
                                {snState22}
                              </p>
                            </div>
                            {/* <p 
        style={{
          color: "#34deeb",
        }}
        >
          {finalResponse}
          {finalResponse.includes("wrong") && (
            "\nOur team will review your order."
          )}
        </p> */}

                            <Button
                              style={{
                                display: contactButtonDisplay,
                                margin: "0 auto",
                                marginTop: "12px",
                              }}
                              variant="primary"
                              color="primary"
                              onClick={handleOpen}
                            >
                              <span>Contact Us</span>
                            </Button>

                            {/* <Button
                              style={{ marginTop: "15px" }}
                              variant="primary"
                              color="primary"
                              onClick={UseSN2}
                              disabled={snState22Disabled}
                            >
                              {loadingState && (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              )}
                              <span>Activation is in progress</span>
                            </Button> */}
                          </div>
                        ) : (
                          <div>
                            <div />
                          </div>
                        )}
                      </div>

                      <div
                        onClick={() => {
                          setSnState22(0);
                          setSnState11(0);
                        }}
                      >
                        {parseInt(value?.funcam) > 0 ? (
                          <div>
                            <p>{productNameUser}</p>

                            <p
                              style={{
                                paddingRight: "15px",
                                paddingLeft: "15px",
                                color: "#268e00",
                              }}
                            >
                              {/* <span>{message33}</span> */}
                            </p>

                            {/* <TextField
                              type="tel"
                              label="S/N"
                              variant="filled"
                              value={snState33}
                              onChange={(e) => setSnState33(e.target.value)}
                            /> */}
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>


                              <p style={{

                                fontSize: "18px",
                                fontWeight: "bold",
                                marginBottom: 0
                              }}>
                                {snState33}
                              </p>
                            </div>
                            {/* <p 
        style={{
          color: "#34deeb",
        }}
        >
          {finalResponse}
          {finalResponse.includes("wrong") && (
            "\nOur team will review your order."
          )}
        </p> */}

                            <Button
                              style={{
                                display: contactButtonDisplay,
                                margin: "0 auto",
                                marginTop: "12px",
                              }}
                              variant="primary"
                              color="primary"
                              onClick={handleOpen}
                            >
                              <span>Contact Us</span>
                            </Button>

                            {/* <Button
                              style={{ marginTop: "15px" }}
                              variant="primary"
                              color="primary"
                              onClick={UseSN3}
                              disabled={snState33Disabled}
                            >
                              {loadingState && (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              )}
                              <span>Activation is in progress</span>
                            </Button> */}
                          </div>
                        ) : (
                          <div>
                            <div />
                          </div>
                        )}
                      </div>
                    </div>
                    <p
            style={{
              color: "#34deeb",
            }}
          >
            
            {
            // loadingResponse ? (
            //   <Spinner
            //                       as="span"
            //                       animation="border"
            //                       size="sm"
            //                       role="status"
            //                       aria-hidden="true"
            //                     />
            // )
            // :
          autoActivation && 
            (
              `${finalResponse}`?.replaceAll('"', "")?.replaceAll("'", "")?.replaceAll("<br>")?.replaceAll("undefined")?.replaceAll(/undefined/g, '')
            ) 
          
          }
          </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <div />
            </div>
          )}
          
        </div>

        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              backgroundColor: "white",
              width: "75%",
              margin: "0 auto",
              borderRadius: "6px",
            }}
          >
            <h1 style={{ padding: "15px" }}>Contact Us</h1>

            <div style={{ margin: "0 auto", display: "flex", padding: "15px" }}>
              <div
                className={classes.emailFormAlign}
                style={{ display: displayEmail.form }}
              >
                <span>Your email</span>
                <input
                  style={{
                    border: "1px solid #cecece",
                    borderRadius: "6px",
                    width: "100%",
                    height: "30px",
                    padding: "15px",
                  }}
                  type="text"
                  onChange={(e) =>
                    setEmailData({ ...emailData, sender: e.target.value })
                  }
                />

                <br />

                <p>
                  <span>Your message</span>
                </p>
                <textarea
                  style={{
                    border: "1px solid #cecece",
                    borderRadius: "6px",
                    width: "100%",
                    height: "90px",
                    padding: "15px",
                  }}
                  onChange={(e) =>
                    setEmailData({ ...emailData, email: e.target.value })
                  }
                ></textarea>

                <br />

                <Button
                  style={{ marginTop: "15px" }}
                  variant="secondary"
                  color="secondary"
                  onClick={sendEmail}
                >
                  Send
                </Button>
              </div>

              <div
                className="alert alert-success"
                style={{ display: displayEmail.success }}
              >
                <p>
                  <span>Email Sent! We will contact you within 24 hours</span>
                </p>

                <div style={{ paddingBottom: "100%" }} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
      //	</section>
    );
  })
);
